import React, {useCallback, useState} from 'react';
import styled from "styled-components";
import {Container} from "components";

const CookieContainer = styled.div`
    background: #000;
    padding: 10px;
`;

const CookieContent = styled(Container)`
    padding: 10px;
    color: #fff;
	display: flex;
    background: #c80502;
    line-height:20px;
    font-size: 14px;
`;

const CookieButtonWrapper = styled.div`

`;

const ExternalLink = styled.a`
	text-decoration:underline;
`;


const CookieButton = styled.div`
    background: rgba(0,0,0,.25);
    border: 1px solid #fff;
    display: inline-block;
    padding: 0 10px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin-right:25px;
    margin-left:25px;
`;

const cookie_policy_accepted_str = 'isCookiePolicyAccepted=true';
const isCookiePolicyAccepted = () => {
	return document.cookie.includes(cookie_policy_accepted_str);
};


export const CookieNotice: React.FC = () => {
	const [is_cookie_policy_accepted, setCookiePolicyState] = useState(isCookiePolicyAccepted());

	const closeCookiePolicy = useCallback(() => {
		setCookiePolicyState(true);
	}, []);

	if (is_cookie_policy_accepted) {
		return null;
	}

	/*
	If the cookie policy hasn't been seen yet, we want to add in a cookie so it doesn't show again,
	regardless if the user clicks 'Continue' or not. This replicates the same behaviour on motogp.com.
	 */
	const date = new Date();
	date.setTime(date.getTime() + (1000 * 60 * 60 * 24 * 365 * 3)); // Expires after 3 years
	document.cookie = cookie_policy_accepted_str + ';expires=' + date.toUTCString() + ';';

	return (
		<CookieContainer>
			<CookieContent>
				<p>We use cookies to ensure that we give you the best experience on our website and to ensure we
					show
					advertising that is relevant to you. By continuing to use our website, you agree to our use
					of such
					cookies. You can change this and find out more by following: <ExternalLink
						href="https://www.motogp.com/en/cookies" target="_blank" rel="noopener noreferrer">
						Cookie Policy </ExternalLink>
				</p>
				<CookieButtonWrapper>
					<CookieButton onClick={closeCookiePolicy}>Continue</CookieButton>
				</CookieButtonWrapper>
			</CookieContent>
		</CookieContainer>
	);
};