import styled from "styled-components";

export const ModalInner = styled.div`
	background: #fff;
	width: 100%;
	max-width: 500px;
	padding: 20px 30px;
	box-sizing: border-box;
	max-height: 100%;
	max-height: 100vh;
	position: relative;
	overflow: auto;
	border-radius: 5px;
`;

export const CloseModalButton = styled.button`
	background: none;
	padding: 0;
	border: 0;
	position: absolute;
	right: 10px;
	top: 10px;
	cursor: pointer;
`;

export * from "./ModalError";
export * from "./ModalOverlay";
