import {call, put} from "typed-redux-saga";
import {Api, extractErrorMessage} from "modules/utils";
import * as actions from "modules/actions";
import {AxiosError} from "axios";
import {IApiResponse} from "modules/utils/Api/HTTPClient";

export const fetchChecksumsJSONSaga = function* () {
	try {
		const {data: response} = yield* call(Api.JSON.checksums);
		yield* put(actions.fetchChecksumsJSONSuccess(response));
	} catch (e) {
		yield* put(actions.fetchChecksumsJSONFailed());
	}
};

// export const fetchConstructorsJSONSaga = function* () {
// 	try {
// 		const {data: response = yield* call(Api.JSON.constructors);
// 		ApiError.CHECK(response);
// 		yield* put(actions.fetchConstructorsJSONSuccess(response));
// 	} catch (e) {
// 		yield* put(actions.fetchConstructorsJSONFailed(e));
// 	}
// };

export const fetchEventsJSONSaga = function* () {
	try {
		const {data: response} = yield* call(Api.JSON.events);

		yield* put(actions.fetchEventsJSONSuccess(response));
	} catch (e) {
		yield* put(actions.fetchEventsJSONFailed());
	}
};

export const fetchRidersJSONSaga = function* () {
	try {
		const {data: response} = yield* call(Api.JSON.riders);
		yield* put(actions.fetchRidersJSONSuccess(response));
	} catch (e) {
		const err = extractErrorMessage(e as AxiosError<IApiResponse>);
		yield* put(actions.globalError(err));
	}
};
export const fetchHistoricalStatsSaga = function* () {
	try {
		const {data: response} = yield* call(Api.JSON.historical_stats);
		yield* put(actions.fetchHistoricalSuccess(response));
	} catch (e) {
		const err = extractErrorMessage(e as AxiosError<IApiResponse>);
		yield* put(actions.globalError(err));
	}
};

export const fetchFaqSaga = function* () {
	try {
		const {data: response} = yield* call(Api.JSON.faq);
		yield* put(actions.fetchFaqSuccess(response));
	} catch (e) {
		yield* put(actions.fetchFaqFailed());
	}
};

export const fetchHelpPagesSaga = function* () {
	try {
		const {data: response} = yield* call(Api.JSON.help_pages);
		yield* put(actions.fetchHelpPagesSuccess(response));
	} catch (e) {
		yield* put(actions.fetchHelpPagesFailed());
	}
};

export const fetchCountriesSaga = function* () {
	try {
		const {data: response} = yield* call(Api.JSON.countries);
		yield* put(actions.fetchCountriesSuccess(response));
	} catch (e) {
		yield* put(actions.fetchCountriesFailed());
	}
};
