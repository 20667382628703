import styled from "styled-components";

export const ButtonPrimary = styled.button`
	background-color: #c80502;
	border: none;
	border-radius: 4px;
	max-width: 242px;
	height: 40px;
	color: #ffffff;
	font-family: "MotoGPText";
	font-size: 14px;
	line-height: 14px;
	width: 100%;
	margin: 0 auto;
	cursor: pointer;
	text-transform: uppercase;
	outline: none;
	display: block;

	&:disabled {
		background: #b8b8b8;
		cursor: default;
	}
`;
