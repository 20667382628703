import React from "react";
import styled from "styled-components";
import {useMediaQuery, useSelectedEvent} from "modules/utils";
import rewind from "assets/img/Rewind.svg";
import YouTube, {Options} from "react-youtube";
import {Container} from "components/Structure";

const Wrapper = styled(Container)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 100px;
	width: 100%;
	> div {
		flex: 1;
	}
	@media screen and (max-width: 960px) {
		flex-direction: column;
		margin-top: 30px;
	}
`;

const Description = styled.div`
	color: #ffffff;
	font-family: "Heebo", sans-serif;
	font-size: 20px;
	font-weight: 500;
	letter-spacing: 0;
	line-height: 30px;
	padding-left: 15px;
	padding-right: 30px;
	img {
		display: block;
		margin-bottom: 30px;
	}
	@media screen and (max-width: 960px) {
		text-align: center;
		padding: 0;
		margin-bottom: 20px;
		img {
			margin: 0 auto 20px;
		}
	}
`;

export const VideoIntegration: React.FC = () => {
	const event = useSelectedEvent();
	const is_mobile = useMediaQuery("(max-width: 960px)");

	if (!event?.pole_position_video) {
		return null;
	}

	const opts: Options = {
		height: is_mobile ? "211" : "382",
		width: is_mobile ? "auto" : "583",
		playerVars: {
			rel: 0,
		},
	};

	return (
		<Wrapper>
			<Description>
				<img src={rewind} alt="icon" />
				{event?.pole_position_text}
			</Description>
			<div>
				{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
				{/* @ts-ignore: Legacy*/}
				<YouTube videoId={event.pole_position_video} opts={opts} />
			</div>
		</Wrapper>
	);
};
