import {createReducer} from "redux-act";
import {hideCrossPromotionModal, showCrossPromotionModal} from "modules/actions";
import {CrossPromotionModalType} from "modules/utils";

// import {PersistStorage} from "modules/utils/PersistStorage";

export interface IModals {
	crossPromotion: CrossPromotionModalType;
}

// const isShown = PersistStorage.GET("CrossPromotionHide") === "true";

const defaultState: IModals = {
	crossPromotion: CrossPromotionModalType.Hidden,
};

export const modals = createReducer<typeof defaultState>({}, defaultState)
	.on(hideCrossPromotionModal, (state) => ({
		...state,
		crossPromotion: CrossPromotionModalType.Hidden,
	}))
	.on(showCrossPromotionModal, (state) => ({
		...state,
		crossPromotion: CrossPromotionModalType.Show,
	}));
