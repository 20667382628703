import {Preloader} from "components/Preloader";
import {isDataRequested} from "modules/selectors";
import React, {Fragment, PropsWithChildren, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import {Storage} from "modules/utils";
import qs from "qs";
import {userLogin} from "modules/actions";

export const HOCSSO: React.FC<PropsWithChildren> = ({children}) => {
	const [show_child, setShowChild] = useState(false);
	const is_data_requested = useSelector(isDataRequested);

	const saved_code_verifier = Storage.GET("saved_code_verifier");
	const {search} = useLocation();
	const history = useHistory();
	const params = qs.parse(search.substring(1));
	const dispatch = useDispatch();

	useEffect(() => {
		if (params.code && saved_code_verifier && !is_data_requested) {
			dispatch(
				userLogin({
					code: params.code as string,
					verifier: saved_code_verifier,
				})
			);

			history.replace({
				search: "",
			});
		}
	}, [dispatch, params, saved_code_verifier, is_data_requested, history]);

	useEffect(() => {
		setShowChild(!is_data_requested);
	}, [is_data_requested]);

	return show_child ? <Fragment>{children}</Fragment> : <Preloader />;
};
