import {put, call} from "typed-redux-saga";
import * as actions from "modules/actions";
import {IPostContactForm, ISagaAction} from "modules/types";
import {Api, extractErrorMessage} from "modules/utils";
import {AxiosError} from "axios";
import {IApiResponse} from "modules/utils/Api/HTTPClient";

export const postContactFormSaga = function* (action: ISagaAction<IPostContactForm>) {
	try {
		yield* call(Api.Contact.save, action.payload);

		yield* put(actions.postContactFormSuccess());
	} catch (e) {
		const error = {text: extractErrorMessage(e as AxiosError<IApiResponse>)};
		yield* put(actions.postContactFormFailed(error));
	}
};
