import {createAction} from "redux-act";
import {
	IGameBar,
	ILeaderboard,
	ILeaderboardPayload,
	IPredictionSavePayload,
	IQuestionPredictionPayload,
	IQuestionPredictionResponse,
	TTimerState,
} from "modules/types";

export const postPredictorPrediction = createAction<IPredictionSavePayload>();
export const postPredictorPredictionSuccess = createAction<IQuestionPredictionResponse>();
export const postPredictorPredictionFailed = createAction();

export const fetchUserPrediction = createAction<IQuestionPredictionPayload>();
export const fetchUserPredictionSuccess = createAction<IQuestionPredictionResponse>();

export const fetchGameBar = createAction<IQuestionPredictionPayload>();
export const fetchGameBarSuccess = createAction<IGameBar>();

export const clearPredictionSavedState = createAction();

export const setTimerState = createAction<TTimerState>();

export const fetchLeaderBoard = createAction<ILeaderboardPayload>();
export const fetchLeaderBoardSuccess = createAction<ILeaderboard>();
export const getMoreLeaderboard = createAction();
export const clearLeaderBoard = createAction();
