import { createReducer } from 'redux-act';
import { closeInfoBlock, fetchFaqSuccess, fetchHelpPagesSuccess } from "modules/actions";
import { Storage } from "modules/utils";

export interface IFaqItem {
	title: string;
	text: string
}

export interface IHelpPage extends IFaqItem {
	type: string
}

export interface IHelpPages {
	howtoplay: IHelpPage,
	prizes: IHelpPage,
	terms: IHelpPage,
}

export interface IHelpsReducer {
	help_pages: IHelpPages | Record<string, unknown>;
	faq: IFaqItem[];
	info: boolean;
}

const initialState: IHelpsReducer = {
	help_pages: {},
	faq: [],
	info: JSON.parse(Storage.GET('info') || 'true') as boolean,
};

export const help = createReducer<IHelpsReducer>({}, initialState)
	.on(fetchHelpPagesSuccess, (state, result) => ({
		...state,
		help_pages: result
	}))
	.on(fetchFaqSuccess, (state, result) => ({
		...state,
		faq: result
	}))
	.on(closeInfoBlock, state => {
		Storage.SET('info', 'false');

		return ({
			...state,
			info: false,
		})
	})
;

