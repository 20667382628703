import {IStore} from "modules/types";
import {get, sortBy} from "lodash";
import {createSelector} from "reselect";
import {getLastCompleteEvent} from "modules/selectors";

export const getRiders = (store: IStore) => store.riders;
export const getHistoricalStats = (store: IStore) => store.historical_stats;

export const getRidersWithStats = createSelector(
	getRiders,
	getLastCompleteEvent,
	(riders, event) => {
		if (!event) {
			return riders;
		}

		return sortBy(
			riders.map((rider) => {
				const {stats} = rider;
				const event_stats = get(stats.events, event.id);
				const prev_event_points = event_stats ? event_stats.points : "-";
				const race_time_float = event_stats ? parseFloat(event_stats.race_time_float) : "";

				return {
					...rider,
					race_time_float,
					prev_event_points,
				};
			}),
			({stats}) => -stats.season_points
		);
	}
);
