import { differenceBy, first, flatten, flow, get, identity, isEmpty, last, partial, toPairs, eq } from 'lodash';
import { SagaIterator } from 'redux-saga';
import { all, call, race, select, take, delay } from 'redux-saga/effects';
import * as actions from 'modules/actions';
import { checksumsSelector, getActualEvent } from 'modules/selectors';
import {
	fetchChecksumsJSONSaga,
	fetchEventsJSONSaga,
} from 'modules/sagas';

const ONE_MIN = 60;
const FIVE_MIN = 300;
const THOUSAND = 1000;

export type TLiveScoreSection = 'predictor';

/**
 * If we need add some additional actions.
 * ladder - just a sections of APP
 *
 */
const mapChecksumsToSaga = {
	events: fetchEventsJSONSaga,
};

export const ChecksumsToSaga = function* (
): SagaIterator {

	/**
	 * I think here we should add a check on lockout
	 * if current is state is locked  then we request checksums per minute otherwise per hour
	 */
	const event = yield select(getActualEvent);
	const is_event_active = eq(event.status, 'active');

	const TIME = THOUSAND * (is_event_active ? FIVE_MIN : ONE_MIN);

	const { stopped } = yield race({
		wait: delay(TIME),
		stopped: take(actions.unsubscribeFromLiveScores)
	});

	if (!stopped) {
		const [old_checksums] = yield all([
			select(checksumsSelector),
			call(fetchChecksumsJSONSaga)
		]);

		const new_checksums = yield select(checksumsSelector);

		const requestsForChanges = flatten(differenceBy(
			toPairs<string>(old_checksums),
			toPairs<string>(new_checksums),
			(arr) => {
				return last<string>(arr ? arr : ['']);
			}
		).map(
			flow([
				first,
				partial(get, mapChecksumsToSaga),
			])
		).filter(identity));

		if (!isEmpty(requestsForChanges)) {
			yield all(requestsForChanges.map((request) => {
				return call(request);
			}));
		}

		yield call(ChecksumsToSaga);
	}
};

export default ChecksumsToSaga;


