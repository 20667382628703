import React from 'react';
import styled from 'styled-components';
// import checked_icon from '../../../../assets/img/icons/checked.svg';
import { uniqueId } from 'lodash';

interface ICheckbox {
	readonly onChange: (e: React.SyntheticEvent<EventTarget>) => void;
	readonly children: React.ReactNode;
	readonly name: string;
	readonly id?: string;
	readonly switch_position?: string;
	readonly type?: string;
	readonly is_radio?: boolean;
	readonly width?: string;
	title: string;
	checked: boolean;
}

const StyledCheckbox = styled.div`
	margin: 0 auto;
	width: 100%;
	position: relative;
	padding: 5px 0;
	display: flex;
	cursor: pointer;
	flex-direction: ${({switch_position}: ICheckboxStyled) => switch_position};
	align-items: center;
`;

interface ICheckboxStyled {
	switch_position?: string;
}

const Checkbox = styled.input`
	width: 43px;
	height: 24px;
	-webkit-appearance: none;
	-moz-appearance: none;
	background: #c6c6c6;
	outline: none;
	border-radius: 50px !important;
	box-shadow: inset 0 0 5px rgba(0, 0, 0, .2);
	transition: 0.5s;
	position: relative;
	flex: 1 0 auto;

	&:checked {
		background: #C80502;

		&::before {
			left: 20px;
		}
	}

	&::before {
		content: '';
		position: absolute;
		width: 22px;
		height: 22px;
		border-radius: 50%;
		top: 50%;
		left: 0;
		background: #fff;
		transform: translateY(-50%);
		box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
		transition: 0.5s;
	}
`;

interface ILabel {
	readonly width?: string;
}

const Label = styled.label`
	transition: .4s;
	position: relative;
	line-height: 1.4;
	font-size: 14px;
	vertical-align: baseline;
	text-align: left;
	max-width: ${({width}: ILabel) => (width || '100%')};
	box-sizing: border-box;
	width: 100%;
	display: inline-block;
	color: #232323;
	font-family: 'MotoGPText';
`;

export const ToggleSwitchStyled: React.FC<ICheckbox> = ({
	onChange,
	children,
	switch_position = 'row',
	type = 'checkbox',
	id = uniqueId('checkbox'),
	width,
	...rest
}) => {

	return (
		<StyledCheckbox>
			<Label
				htmlFor={id}
				width={width}
			>
				{children}
			</Label>
			<Checkbox
				onChange={onChange}
				id={id}
				type={'checkbox'}
				{...rest}
			/>
		</StyledCheckbox>
	)
};

export default ToggleSwitchStyled;
