import styled from "styled-components";

export const PageHeader = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 20px auto;
	h2 {
		color: #000000;
		font-family: "Heebo", sans-serif;
		font-size: 20px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 24px;
	}
	p,
	li {
		color: #000000;
		font-family: "Roboto", sans-serif;
		font-size: 14px;
		letter-spacing: 0;
		line-height: 20px;
	}

	@media screen and (max-width: 960px) {
		flex-direction: column;
		> div {
			width: 100%;
		}
	}
`;
