import {SECRET} from "modules/utils/Constant";

const secreteKey = `motogp-predictor-isSecretPassed`;
export abstract class SecretGateController {
	private static _count = 0;

	static get IS_SECRET_PASSED() {
		if (SECRET) {
			return Boolean(JSON.parse(sessionStorage.getItem(secreteKey) || "false"));
		}

		return true;
	}

	public static handleSecretClick = () => {
		if (SecretGateController.IS_SECRET_PASSED) {
			return;
		}

		SecretGateController._count += 1;

		if (SecretGateController._count >= 10) {
			sessionStorage.setItem(secreteKey, "true");
			window.location.reload();
		}
	};
}
