import {createReducer} from "redux-act";
import {IPredictionReducer} from "modules/types";
import {
	fetchUserPredictionSuccess,
	postPredictorPrediction,
	postPredictorPredictionSuccess,
	clearPredictionSavedState,
	setTimerState,
	fetchGameBarSuccess,
	fetchLeaderBoardSuccess,
	clearLeaderBoard,
} from "modules/actions";

const initialState = {
	is_result_saving: false,
	is_result_saved: false,
	timer_ended: false,
	time_input_length: {
		minutes: 1,
		seconds: 2,
		milliseconds: 3,
	},
	user_prediction: {
		prediction: null,
		question: {
			id: 0,
			event_id: 0,
			title: "",
			locked: false,
		},
	},
	game_bar: {
		event_name: "",
		prediction_points: null,
		start_race: "",
		total_points: 0,
	},
	leaderboard: {
		leaderboard: [],
		next: false,
	},
};

export const predictor = createReducer<IPredictionReducer>({}, initialState)
	.on(fetchUserPredictionSuccess, (state, payload) => ({
		...state,
		user_prediction: {
			...payload,
		},
	}))
	.on(postPredictorPrediction, (state) => ({
		...state,
		is_result_saving: true,
	}))
	.on(postPredictorPredictionSuccess, (state, payload) => ({
		...state,
		is_result_saved: true,
		is_result_saving: false,
		user_prediction: {
			...payload,
		},
	}))
	.on(clearPredictionSavedState, (state) => ({
		...state,
		is_result_saved: false,
	}))
	.on(setTimerState, (state, payload) => ({
		...state,
		timer_ended: payload,
	}))
	.on(fetchGameBarSuccess, (state, payload) => ({
		...state,
		game_bar: {
			...payload,
		},
	}))
	.on(fetchLeaderBoardSuccess, (state, payload) => ({
		...state,
		leaderboard: {
			...payload,
		},
	}))
	.on(clearLeaderBoard, (state) => ({
		...state,
		leaderboard: {
			leaderboard: [],
			next: false,
		},
	}));
