import {isString, get} from "lodash";
import {SagaIterator} from "redux-saga";
import {put, call, select} from "typed-redux-saga";
import {ISagaAction} from "modules/types";
import {ApiError, extractErrorMessage, User} from "modules/utils";
import {isLoggedIn} from "modules/selectors";
import * as actions from "modules/actions";
import {AxiosError} from "axios";
import {IApiResponse} from "modules/utils/Api/HTTPClient";

type TPayload = ApiError | Error | string;

// eslint-disable-next-line @typescript-eslint/require-await
const defaultErrorHandler = async (payload: TPayload) => {
	if (isString(payload)) {
		throw new Error(payload);
	}

	if (isString(payload.message)) {
		throw new Error(payload.message);
	}

	if (payload instanceof ApiError) {
		throw new Error(payload.text);
	}
};

const onError = {
	*[ApiError.AUTH_REQUIRED](error: TPayload): SagaIterator {
		if (yield* select(isLoggedIn)) {
			User.CLEAR();
			window.location.reload();
		}

		yield* call(defaultErrorHandler, error);
	},
};

export const errorsManagerSaga = function* ({payload}: ISagaAction<TPayload>): SagaIterator {
	const error_code = get(payload, "status", "");

	try {
		const onErrorHandler = get(onError, error_code, defaultErrorHandler);
		yield* call(onErrorHandler, payload);
	} catch (err) {
		const error = {text: extractErrorMessage(err as AxiosError<IApiResponse>)};
		yield* put(
			actions.showGlobalError({
				text: error.text || "",
				code: error_code || 0,
			})
		);
	}
};
