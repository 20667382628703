import {IUser} from "modules/types";
import {Storage} from "modules/utils/Storage";
import {IS_API_ON_THE_SAME_HOST} from "modules/utils/Api";

const _cs = [
	"\x42\x79",
	"\x65\x20",
	"\x70\x6c",
	"\x20\x6d\x65",
	"\x6c\x65\x74",
	"\x65\x61\x73",
	"\x20\x69",
];
const KEY = _cs[2] + _cs[5] + _cs[1] + _cs[4] + _cs[3] + _cs[6] + "n";
export abstract class User {
	public static SAVE(user: IUser, session_id?: string): void {
		if (!IS_API_ON_THE_SAME_HOST && typeof session_id === "string") {
			Storage.SET("sid", session_id);
		}
	}

	public static CLEAR(keys: string[] = []): void {
		// eslint-disable-next-line @typescript-eslint/unbound-method
		["user", "is_authenticated", "sid", "is_admin"].forEach(Storage.REMOVE);
	}

	public static GET(): IUser | Record<string, unknown> {
		return JSON.parse(Storage.GET("user") || "{}");
	}

	public static IS_AUTHENTICATED(): boolean {
		return JSON.parse(Storage.GET("is_authenticated") || "false");
	}

	public static KEY(): string {
		return KEY;
	}
}
