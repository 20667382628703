import React, {PropsWithChildren} from "react";
import styled from "styled-components";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 15px;

	&.hidden {
		display: none;
	}

	> * {
		outline: none;
		box-sizing: border-box;
	}
`;

const Error = styled.span`
	line-height: 16px;
	font-size: 14px;
	font-family: "Heebo", sans-serif;
	padding-bottom: 4px;
	color: #c80502;
`;

const Title = styled.p`
	color: #000000;
	font-family: "Heebo", sans-serif;
	font-size: 14px;
	letter-spacing: 0;
	line-height: 21px;
	padding-bottom: 5px;
`;

interface IProps extends PropsWithChildren {
	title?: string;
	error_message?: string;
	className?: string;
}

export const FormGroup: React.FC<IProps> = ({error_message, title, children, ...rest}) => {
	return (
		<Wrapper {...rest}>
			{title && <Title>{title}</Title>}

			{error_message && <Error>{error_message}</Error>}
			{children}
		</Wrapper>
	);
};
